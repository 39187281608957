<template>
 <div class="row">
  <div class="col-12">
    <VolumeJob />
  </div>

  <div class="col-12">
    <VolumeBirthdayMonth />
  </div>

  <div class="col-md-6 col-sm-12">
    <VolumeMarital />
  </div>
  
  <div class="col-md-6 col-sm-12">
    <VolumeSurvey />
    <VolumeBirthdayToday />
  </div>
 </div>
</template>

<script>
import VolumeMarital from "../charts/VolumeMarital.vue";
import VolumeJob from "../charts/VolumeJob.vue";
import VolumeSurvey from "../charts/VolumeSurvey.vue";
import VolumeBirthday from "../charts/VolumeBirthday.vue";
import VolumeBirthdayMonth from "../charts/VolumeBirthdayMonth.vue";
import VolumeBirthdayToday from "../charts/VolumeBirthdayToday.vue";

export default {
  components: {
    VolumeMarital,
    VolumeJob,
    VolumeSurvey,
    VolumeBirthday,
    VolumeBirthdayMonth,
    VolumeBirthdayToday
  },
  data() {
    return {
      user: {},
      updateModal: false,
      form: {},
      roles: [],
      rooms: [],
      inbox: [],
      statuses: [],
      params: {
        sorttype: "desc",
        sortby: "id",
        row: 3,
      },
      isStatus: true,
      isChangeProfile: false,
      isRekap: false,
      isPasswordValid: null, //null/boolean
      invalidPassword: '',
    };
  },
  methods: {
     validatePassword(item) {
      if (item.password.length < 6) {
        this.invalidPassword = 'Password kurang dari 6 karakter!!';
        this.isPasswordValid = false;
        return false;
      } else if (item.password != item.password_confirmation) {
        this.invalidPassword = 'Konfirmasi password tidak sama!!';
        this.isPasswordValid = false;
        return false;
      } else {
        this.invalidPassword = '';
        this.isPasswordValid = null;
        return true;
      }
    },
     updatePassword() {
      if (this.validatePassword(this.user)) {
        var loading = this.$loading.show();
        this.$store
          .dispatch("user/changePassword", { id: this.user.id, password: this.user.password })
          .then(() => {
            this.$toast.success("Berhasil merubah password user");
            loading.hide();
          })
          .catch((e) => {
            this.$toast.error(e);
            loading.hide();
          });
      } else {
        this.$toast.error(this.invalidPassword);
      }
    },
      inputPassword(){
        this.invalidPassword = '';
        this.isPasswordValid = null;
      },
     update() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("user/updateUser", { id: this.user.id, data: this.user })
        .then(() => {
          this.$toast.success("Berhasil merubah data user");
          loading.hide();
        })
        .catch((e) => {
          loading.hide();
        });
    },
    onClick(params) {
      switch (params) {
        case 'create_eproduct':
          this.$router.replace({ path: "online-chat" });
          break;
        case 'status':
          
             this.isStatus = true;
             this. isChangeProfile = false;
             this.isRekap = false;
          
          break;
        case 'change_profile':
    
             this.isChangeProfile = true;
             this. isStatus = false;
             this.isRekap = false;
         
          break;
        case 'report':
         
             this.isRekap = true;
             this.isStatus = false;
             this.isChangeProfile = false;
          
          break;
        default:
          break;
      }
    },
    getMe() {
      this.$store
        .dispatch("auth/me")
        .then((resp) => {
          this.user = resp.data;
        })
        .catch((e) => {
        });
    },
    getStatus() {
      this.$store
        .dispatch("dashboard/getStatus")
        .then((resp) => {
          this.statuses = [];
          resp.data.forEach(element => {
            if (this.user.verificator == 1 && (element.status == 'PENGAJUAN' || element.status == 'DIKEMBALIKAN' || element.status == 'PROSES')) {
              this.statuses.push(element)
            } else if (this.user.verificator == 2 && (element.status == 'PROSES' || element.status == 'DIEDIT' || element.status == 'LEGAL DRAFTING')) {
              this.statuses.push(element)
            } else if (this.user.verificator == 3 && (element.status == 'LEGAL DRAFTING' || element.status == 'APPROVED SUNCANG')) {
              this.statuses.push(element)
            } else if (this.user.verificator == 4 && (element.status == 'APPROVED SUNCANG' || element.status == 'APPROVED KASUBAG')) {
              this.statuses.push(element)
            } else if (this.user.verificator == 5 && (element.status == 'APPROVED KASUBAG' || element.status == 'APPROVED KABAG')) {
              this.statuses.push(element)
            } else if (this.user.verificator == 6 && (element.status == 'APPROVED KABAG' || element.status == 'APPROVED ASSISTANT')) {
              this.statuses.push(element)
            } else if (this.user.verificator == 7 && (element.status == 'APPROVED ASSISTANT' || element.status == 'APPROVED SEKDA')) {
              this.statuses.push(element)
            } else if (this.user.verificator == 0) {
               this.statuses.push(element)
            }
          });
        })
        .catch((e) => {
        });
    },
  },
  mounted() {
    this.getMe();
    this.getStatus();
  },

  computed: {
    computedRole() {
      return this.roles.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    },
  },
};
</script>

<style scoped>
.border-top {
  border-top: 3px solid #1d4374 !important;
}

.scroll {
  overflow-y: auto;
  height: 150px;
}
</style>

<style>
.table {
  display: table;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.rounded-circle {
    border-radius: 50% !important;
    border: 5px solid black;
}

.profile {
  color: #5b9bd1;
  font-weight: bold;
}

.dashboard-card {
  background-color: #dff0d8;
  padding-bottom: 0;
}

img.center {
    display: block;
    margin: 0 auto;
}
</style>