<template>
  <div class="card border-top rounded shadow p-3">
    <div class="row align-items-center">
      <div class="col-9">
        <b>Status Pernikahan Alumni 88</b>
      </div>
    </div>
    <hr />
    <CChartBar
      :datasets="defaultDatasets"
      :labels="maritalStatusLabels"
      :options="computedOptions"
      style="height:300px"
    />
  </div>
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs';

export default {
  name: 'CChartBarExample',
  components: { CChartBar },
  data() {
    return {
      sims: [],
      params: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
      maritalStatusLabels: ['Belum Kawin', 'Kawin', 'Cerai Hidup', 'Cerai Mati'],
    };
  },
  computed: {
    defaultDatasets() {
      return [
        {
          label: 'Status Pernikahan alumni 88',
          backgroundColor: this.generateRandomColors(4),
          data: this.countMaritalStatus(),  // Use the function to count martial_status
        },
      ];
    },
    defaultOptions() {
      return {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }]
    }
      };
    },
    computedOptions() {
      return { ...this.defaultOptions };
    },
  },
  methods: {
    generateRandomColors(count) {
      const colors = [];
      for (let i = 0; i < count; i++) {
        colors.push(this.getRandomColor());
      }
      return colors;
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getGrafikVolumeTransactionBySim() {
      this.$store
        .dispatch('survey/getSurvey', this.params)
        .then((resp) => {
          this.sims = resp.data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    countMaritalStatus() {
      const countArray = [0, 0, 0, 0];
      this.sims.forEach(item => {
        switch (item.marital_status) {
          case "1":
            countArray[0]++;
            break;
          case "2":
            countArray[1]++;
            break;
          case "3":
            countArray[2]++;
            break;
          case "4":
            countArray[3]++;
            break;
          default:
            break;
        }
      });
      console.log( countArray)
      return countArray;
    },
    change() {
      this.getGrafikVolumeTransactionBySim();
    },
  },
  mounted() {
    this.getGrafikVolumeTransactionBySim();
  },
};
</script>