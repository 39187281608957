<template>
  <div class="card border-top rounded shadow p-3">
    <div class="row align-items-center">
      <div class="col-9">
        <b>Total Alumni Terdaftar</b>
      </div>
    </div>
    <hr />
    <div class="text-center">
      <h1>{{ this.surveys.length }}</h1>
    </div>
  </div>
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs';

export default {
  name: 'CChartBarExample',
  components: { CChartBar },
  data() {
    return {
      surveys: [],
      params: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
      totalSurveys: 0, // Initialize the total number of surveys
    };
  },
  computed: {
    defaultDatasets() {
      return [
        {
          label: 'Number of surveys by marital status',
          backgroundColor: this.generateRandomColors(4),
          data: this.countMaritalStatus(),
        },
      ];
    },
    defaultOptions() {
      return {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          y: [
            {
              beginAtZero: true,
              ticks: {
                callback: function (value) {
                  if (Number.isInteger(value)) {
                    return value;
                  }
                },
              },
            },
          ],
        },
      };
    },
    computedOptions() {
      return { ...this.defaultOptions };
    },
  },
  methods: {
    generateRandomColors(count) {
      const colors = [];
      for (let i = 0; i < count; i++) {
        colors.push(this.getRandomColor());
      }
      return colors;
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getSurveyData() {
      this.$store
        .dispatch('survey/getSurvey', this.params)
        .then((resp) => {
          this.surveys = resp.data.data;
          console.log(this.surveys)
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateTotalSurveys() {
      this.totalSurveys = this.surveys.length; // Calculate the total number of surveys
    },
    change() {
      this.getSurveyData();
      this.updateTotalSurveys(); // Update the total number of surveys
    },
  },
  mounted() {
    this.getSurveyData();
    this.updateTotalSurveys(); // Initialize the total number of surveys
  },
};
</script>
