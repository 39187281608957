<template>
  <div class="card border-top rounded shadow p-3">
    <div class="row align-items-center">
      <div class="col-9">
        <b>Pekerjaan Alumni 88</b>
      </div>
    </div>
    <hr />
    <CChartBar
      :datasets="defaultDatasets"
      :labels="jobLabels"
      :options="computedOptions"
      style="height:300px"
    />
  </div>
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs';

export default {
  name: 'CChartBarExample',
  components: { CChartBar },
  data() {
    return {
      sims: [],
      params: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
    };
  },
  computed: {
    defaultDatasets() {
      return [
        {
          label: 'Status Pekerjaan alumni 88',
          backgroundColor: this.generateRandomColors(this.sims.length),
          data: this.countJobs(), // Use the function to count job
        },
      ];
    },
    defaultOptions() {
      return {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      };
    },
    computedOptions() {
      return { ...this.defaultOptions };
    },
    jobLabels() {
      return this.sims.map(item => item.jobs.name);
    },
  },
  methods: {
    generateRandomColors(count) {
      const colors = [];
      for (let i = 0; i < count; i++) {
        colors.push(this.getRandomColor());
      }
      return colors;
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getGrafikVolumeTransactionBySim() {
      this.$store
        .dispatch('survey/getSurvey', this.params)
        .then((resp) => {
          this.sims = resp.data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    countJobs() {
      const countArray = [];
      this.sims.forEach((item) => {
        countArray.push(1); // Count each job occurrence
      });
      return countArray;
    },
    change() {
      this.getGrafikVolumeTransactionBySim();
    },
  },
  mounted() {
    this.getGrafikVolumeTransactionBySim();
  },
};
</script>