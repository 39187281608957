<template>
  <div class="card border-top rounded shadow p-3">
    <div class="row align-items-center">
      <div class="col-12">
        <b>Yang Berulang Tahun Hari ini</b>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <table class="table table-bordered table-hover">
          <thead class="thead-light">
          </thead>
          <tbody>
            <tr v-if="birthdayData.length === 0">
              <td class="name-cell">Tidak ada yang ulang tahun hari ini</td>
            </tr>
            <tr v-for="name in birthdayData" :key="name.id">
              <td class="name-cell">{{ name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BirthdayTables',
  data() {
    return {
      sims: [],
      params: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
      birthdayData: [],
    };
  },
  methods: {
    getGrafikVolumeTransactionBySim() {
      this.$store
        .dispatch('survey/getSurvey', this.params)
        .then((resp) => {
          this.sims = resp.data.data;
          this.organizeBirthdayData();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    organizeBirthdayData() {
      const today = new Date();
      const todayData = this.sims
        .filter(item => {
          const birthday = new Date(item.birthday);
          return birthday.getDate() === today.getDate() && birthday.getMonth() === today.getMonth();
        })
        .map(item => item.name);

      this.birthdayData = todayData;
    },
    change() {
      this.getGrafikVolumeTransactionBySim();
    },
  },
  mounted() {
    this.getGrafikVolumeTransactionBySim();
  },
};
</script>

<style scoped>
  /* Add any custom styling here */
  .table th,
  .table td {
    text-align: center;
    padding: 10px;
  }

  .month-header {
    background-color: #007bff; /* Blue color, change as needed */
    color: #fff; /* White text, change as needed */
    font-weight: bold;
    font-size: 18px;
  }

  .name-cell {
    font-size: 16px;
  }

  .table-hover tbody tr:hover {
    background-color: #f8f9fa;
  }
</style>
