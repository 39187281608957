<template>
  <div class="card border-top rounded shadow p-3">
    <div class="row align-items-center">
      <div class="col-12">
        <b>Yang Berulang Tahun Bulan Ini</b>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <table class="table table-bordered table-hover">
          <thead class="thead-light">
            <tr>
              <th class="month-header">Tanggal</th>
              <th class="month-header">Orang Yang Sedang Berulang Tahun</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(dayData, dayIndex) in birthdayData" :key="dayIndex">
              <td>{{ `${dayIndex + 1} ${monthName} ${new Date().getFullYear()}` }}</td>
              <td class="name-cell">{{ dayData.join(', ') || 'Tidak ada yang ulang tahun hari ini' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BirthdayTables',
  data() {
    return {
      sims: [],
      params: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
      birthdayData: Array(31).fill([]).map(() => []),
      monthName: '',
    };
  },
  methods: {
    getGrafikVolumeTransactionBySim() {
      this.$store
        .dispatch('survey/getSurvey', this.params)
        .then((resp) => {
          this.sims = resp.data.data;
          this.organizeBirthdayData();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    organizeBirthdayData() {
      // Clear the data for each day before populating
      this.birthdayData = Array(31).fill().map(() => []);

      // Filter data for the current month
      const currentMonth = new Date().getMonth();
      this.monthName = this.getMonthName(currentMonth);
      const currentMonthData = this.sims.filter(item => new Date(item.birthday).getMonth() === currentMonth);

      currentMonthData.forEach(item => {
        const birthdayDay = new Date(item.birthday).getDate();
        this.birthdayData[birthdayDay - 1].push(item.name);
      });
    },
    getMonthName(monthIndex) {
      const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
      ];
      return monthNames[monthIndex];
    },
    change() {
      this.getGrafikVolumeTransactionBySim();
    },
  },
  mounted() {
    this.getGrafikVolumeTransactionBySim();
  },
};
</script>

<style scoped>
  /* Add any custom styling here */
  .table th,
  .table td {
    text-align: center;
    padding: 10px;
  }

  .month-header {
    background-color: #007bff; /* Blue color, change as needed */
    color: #fff; /* White text, change as needed */
    font-weight: bold;
    font-size: 18px;
  }

  .name-cell {
    font-size: 16px;
  }

  .table-hover tbody tr:hover {
    background-color: #f8f9fa;
  }
</style>
