<template>
  <div class="card border-top rounded shadow p-3">
    <div class="row align-items-center">
      <div class="col-12">
        <b>Birthday Tables</b>
      </div>
    </div>
    <hr />
    <div class="row">
      <div v-for="(monthData, index) in birthdayData" :key="index" class="col-md-4 mb-4">
        <table class="table table-bordered table-hover">
          <thead class="thead-light">
            <tr>
              <th class="month-header">{{ birthdayLabels[index] }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="name in monthData" :key="name.id">
              <td class="name-cell">{{ name.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BirthdayTables',
  data() {
    return {
      sims: [],
      params: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
      birthdayLabels: [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
      ],
      birthdayData: Array(12).fill([]),
    };
  },
  methods: {
    getGrafikVolumeTransactionBySim() {
      this.$store
        .dispatch('survey/getSurvey', this.params)
        .then((resp) => {
          this.sims = resp.data.data;
          this.organizeBirthdayData();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    organizeBirthdayData() {
      // Clear the data for each month before populating
      this.birthdayData = Array(12).fill().map(() => []);

      this.sims.forEach(item => {
        const birthdayMonth = new Date(item.birthday).getMonth();
        this.birthdayData[birthdayMonth].push(item);
      });

      // Sort individuals for each month based on birthday
      this.birthdayData.forEach(monthData => {
        monthData.sort((a, b) => {
          const dateA = new Date(a.birthday);
          const dateB = new Date(b.birthday);
          return dateA - dateB;
        });
      });
    },
    change() {
      this.getGrafikVolumeTransactionBySim();
    },
  },
  mounted() {
    this.getGrafikVolumeTransactionBySim();
  },
};
</script>

<style scoped>
  /* Add any custom styling here */
  .table th,
  .table td {
    text-align: center;
    padding: 10px;
  }

  .month-header {
    background-color: #007bff; /* Blue color, change as needed */
    color: #fff; /* White text, change as needed */
    font-weight: bold;
    font-size: 18px;
  }

  .name-cell {
    font-size: 16px;
  }

  .table-hover tbody tr:hover {
    background-color: #f8f9fa;
  }
</style>
